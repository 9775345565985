@font-face {
    font-family: 'Lateef';
    font-style: normal;
    font-weight: 400;
    src: url('./arabic-lateef.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('./roboto/Roboto-Thin.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('./roboto/Roboto-ThinItalic.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./roboto/Roboto-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('./roboto/Roboto-LightItalic.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./roboto/Roboto-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./roboto/Roboto-Italic.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./roboto/Roboto-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('./roboto/Roboto-MediumItalic.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./roboto/Roboto-Bold.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('./roboto/Roboto-BoldItalic.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./roboto/Roboto-Black.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('./roboto/Roboto-BlackItalic.ttf') format('ttf');
}